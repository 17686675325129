/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.min.css";
@import "primeng/resources/themes/lara-dark-blue/theme.css";
@import "styles/area-theme";
@import "styles/classes";
@import "styles/icons";

$headerHeight: 75px;
$footerHeight: 25px;

body {
  margin: 0;
}

h1, h2, h3, h4, h5 {
  margin: 10px 0;
}

header {
  height: $headerHeight;
  display: flex;
  flex-direction: row;

  .logo-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .p-button.p-button-text {
      border: none;
    }
  }


  .header {
    display: flex;
    gap: 10px;
    align-items: center;

    overflow: hidden;

    .logo,
    .app-name {
      display: flex;
      align-items: center;
    }

    .logo {
      padding: 5px 0;

      img {
        height: 100%;
        width: 200px;
      }
    }

    .app-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .header-menu {
    text-align: right;
    flex: 1;
    justify-content: flex-end;
    margin: 0 5px 0 0;
  }

  .p-menu-overlay {
    width: 200px !important;
  }
}


.app {
  height: 100%;
  display: flex;
  flex: 1;

  .menu,
  .p-submenu-header {
    background: none !important;
    border: none !important;
  }

  .sidebar-open {
    height: 100%;
    width: 175px
  }

  .sidebar-closed {
    height: 100%;
    width: 75px
  }


  .component-wrapper {
    width: 100%;
    padding: 5px;

    .component {
      height: 100%;

      .content-wrapper {
        .content {
          form {
            input {
              width: 100%;
            }
          }

          td,
          th {
            p-dropdown,
            .p-dropdown {
              width: 100%;
            }
          }


          .table-caption {
            display: flex;

            .table-caption-table-info {
              display: flex;
              flex: 1;
              flex-direction: row;
              gap: 25px;
              align-items: center;

              .table-caption-text {
                display: flex;
                align-items: center;

                font-weight: 400;
              }

              .table-caption-column-select {
                flex: 1;
              }
            }

            .table-caption-search {
            }
          }

          .table-header-label {
            display: flex;

            .table-header-text {
              flex: 1;
            }

            .table-header-icon {
            }
          }
        }
      }
    }
  }

  .component-wrapper-simple {
    @extend .component-wrapper;
    padding: 0 !important;
  }
}


.login-wrapper {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  .login-form-wrapper,
  .auth-header,
  .auth-footer {
    .logo {
      width: 250px;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    width: 350px;
    height: 350px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 25px;

    .login-form {
      width: 80%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        text-align: center;
      }

      .input-field-info-text {
        margin: 15px 0;
        width: 100%;
      }

      .login-form-sub-button-wrapper {
        display: flex;
        flex-direction: column;

        .login-form-sub-btn {
          margin-top: 15px;
          width: 100%;
        }

        .login-form-sub-btn-wrapper {
          width: 100%;
        }
      }
    }
  }

  .register-form-wrapper {
    height: 600px;
  }

  .register-confirm-form-wrapper {
    height: 250px;
  }

  .auth-header {
    width: 350px;
    height: 75px;
  }

  .auth-footer {
    width: 350px;
    height: 75px;
  }
}


input:not(.p-calendar input),
.p-password input {
  border-radius: 10px;

  &:focus {
    box-shadow: none !important;
  }

  &:hover,
  &:active,
  &:enabled:focus {
  }
}

.p-calendar input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn-wrapper {
  display: flex !important;
  align-items: center !important;
}

.wrapper-right {
  justify-content: flex-end !important;
}

.btn {
}

.icon-btn {
  .p-button {
    background: transparent !important;
    border: 0 !important;
  }

  .pi {
    font-size: 1.275rem !important;
  }
}

.text-btn {
  border: 0 !important;
  padding: 0 !important;

  &:hover {
    border: 0;
  }
}

.icon-btn-without-hover {
  &:hover {
    border: 0 !important;
  }
}

.icon-btn {
  .p-button-label {
    transition-duration: unset !important;
  }

  &:hover {
    border: 0;
  }
}

.danger-btn,
.danger-icon-btn {
  border: 0 !important;

  &:hover {
    border: 0;
  }

  .pi {
    font-size: 1.275rem !important;
  }
}

.hidden-column {
  display: none;
}

.table-edit-input,
.p-multiselect {
  width: 100% !important;
}

.spinner-component-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: center;

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    /* Transparent Overlay */
    &:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.53);
    }
  }
}

.steps {
  display: flex;
  width: 100%;
  justify-content: space-between;

  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;

  .steps-items {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.p-menu {

  width: unset !important;

  .p-menuitem-text {
    line-height: unset !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.p-panelmenu {
  .p-icon-wrapper {
    order: 1; // to be the first item on right side.
    svg {
      margin-right: unset !important;
    }
  }

  .p-menuitem-text {
    flex: 1;
  }

  .p-panelmenu-content {
    border: none !important;
    background: transparent !important;
  }

  .p-panelmenu-panel {
    .p-panelmenu-header-content {
      border: none !important;
      background: transparent !important;
    }
  }
}

.p-datatable-wrapper {
  overflow: visible !important;
}

.add-chip {
  background-color: transparent !important;
  border: 1px dashed #aaa !important;
}

.p-dialog-content {
  overflow: visible !important;
}

app-gameday-player-assignment-dialog .p-dialog-content,
.p-dialog-mask.p-component-overlay {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.p-chip {
  padding: 5px !important;

  .p-chip-text {
    margin: 0 !important;
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 5px !important;
}

.hidden-columns-select {
  border: none !important;

  .p-multiselect-label {
    padding: 0 !important;
  }

  .p-multiselect-label-container {
    .p-multiselect-label-empty {
      visibility: visible !important;
    }
  }

  .p-multiselect-trigger {
    display: none !important;
  }

  .p-overlay {
    left: -100px !important;
  }

  .active-while-panel-open {
    .p-button {
      color: $textColorHighlight;
    }
  }
}

.p-password {
  input {
    width: 100%;
  }
}

p-button {
  .p-button.p-button-icon-only {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 960px) {
  .table-caption {
    flex-direction: column !important;
  }

  .table-caption-table-info {
    flex-direction: column !important;
  }

  .table-caption-btn-wrapper {
    align-items: center !important;
    justify-content: center !important;
  }
}
