//$headerColor: #DC2261; // pink
$headerColor: #a03033;

$textColor: #fff;
$textColorHighlight: #a03033;

$backgroundColor: #122E47;
$backgroundColor2: #061529;
$backgroundColor3: #061D29;

$accentColor: #424b57;
$infoColor: #1ea97c;
$warningColor: #FFC107;
$errorColor: #FF5252;

@font-face {
  font-family: 'ACES_Futurista-Regular';
  src: url('/assets/ACES_Futurista-Regular.woff') format('woff'); /* Safari, Android, iOS */
}

body {
  background-color: $backgroundColor2;
}

body,
body .p-component,
.p-inputtext,
button {
  font-size: 1rem;
  font-family: 'ACES_Futurista-Regular', serif !important;
}

h1,
h2,
h3 {
  color: $textColorHighlight;
}

.app {
  .component-wrapper {
    background-color: $backgroundColor;
  }
}

.btn-base {
  color: $textColor;
  background: $headerColor !important;
  border: none;

  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    background: transparent !important;
  }
}

.btn {
  @extend .btn-base;

  .p-button {
    @extend .btn-base;
  }
}

.icon-btn {
  background-color: transparent !important;
  border: none;

  .p-button {
    color: $textColor;
    background: transparent !important;

    &:hover {
      color: $textColorHighlight;
    }
  }
}

.text-btn {
  background-color: transparent !important;

  .p-button {
    color: $textColor;
    background: transparent !important;
    border: none !important;

    &:hover {
      color: $textColorHighlight;
      background-color: transparent !important;
    }
  }
}

.icon-btn-without-hover {
  &:hover {
    background-color: transparent !important;
  }
}

.icon-btn {
  &:hover {
    background-color: transparent !important;
  }
}

.danger-btn,
.danger-icon-btn {
  background-color: transparent !important;

  &:hover {
    color: $textColorHighlight;
    background-color: transparent !important;
  }
}

.hidden-columns-select {
  .active-while-panel-open {
    .p-button {
      color: $textColorHighlight;
    }
  }
}

.custom-spinner {
  .p-progress-spinner-circle {
    stroke: $textColorHighlight !important;
  }
}

.p-multiselect,
.p-paginator,
.p-dropdown,
input {
  background-color: $backgroundColor;
}

.p-inputtext:enabled:focus,
.p-inputtext:enabled:hover,
.p-multiselect:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: $textColorHighlight;
  box-shadow: none !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $textColorHighlight;
  background: $textColorHighlight;
  box-shadow: none !important;
}

p-checkbox {
  .p-checkbox {
    .p-checkbox-box {
      background-color: $backgroundColor;
    }

    .p-checkbox-box.p-highlight {
      border-color: $textColorHighlight;
      background-color: $textColorHighlight;
    }
  }
}

p-inputSwitch {
  .p-inputswitch {
    &.p-focus .p-inputswitch-slider {
      box-shadow: none !important
    }

    .p-inputswitch-slider {
      background-color: $headerColor;

      &.p-highlight {
        border-color: $textColorHighlight;
        background: $textColorHighlight;
        box-shadow: none !important;
      }
    }


    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background-color: $headerColor;

        &:before {
          background-color: $textColor;
        }
      }
    }
  }

}

p-panel-menu {
  .p-panelmenu {
    .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text,
    .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: $textColorHighlight;
    }


    .p-panelmenu-header-content {
      &:hover {
        .p-panelmenu-header-action {
          color: $textColorHighlight;
        }
      }
    }
  }
}

p-dropdown {

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
    border-color: $headerColor !important;
  }

  .p-dropdown-panel {
    background-color: $backgroundColor2;
  }
}

p-multiselect {
  .p-multiselect-panel,
  .p-multiselect-panel .p-multiselect-header {
    background-color: $backgroundColor2;
  }
}

p-table {
  .p-datatable {
    .p-datatable-header,
    .p-datatable-footer,
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr {
      background-color: $backgroundColor;
    }

    .p-sortable-column.p-highlight,
    .p-sortable-column:not(.p-highlight):hover {
      background-color: transparent !important;
    }

    .p-sortable-column:not(.p-highlight):hover,
    .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: $textColorHighlight;
    }
  }
}

p-sidebar {
  .p-sidebar {
    background-color: $backgroundColor;
  }
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background-color: $headerColor;
  color: $textColor;
}

.p-dialog {
  .p-dialog-header {
    background-color: $backgroundColor;
  }

  .p-dialog-content {
    background-color: $backgroundColor;
    padding-top: 1rem;
  }

  .p-dialog-footer {
    background-color: $backgroundColor;
  }
}

.p-badge {
  background: $headerColor;
  color: $textColor;
}

p-password {
  background-color: transparent;
  border: none;

  .p-password-panel {
    background-color: transparent;
  }
}


p-slider {
  .p-slider {
    .p-slider-range {
      background: $headerColor;
    }

    .p-slider-handle {
      border-color: $headerColor;


      &:hover {
        background: $headerColor;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }
}
