$iconSize: 64px;

.pi.pi-rifle {
  display: inline-block;
  width: $iconSize;
  height: $iconSize;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;

  &::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/weapons/long.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.pi.pi-pistol {
  display: inline-block;
  width: $iconSize;
  height: $iconSize;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;

  &::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/weapons/pistol.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.pi.pi-sniper {
  display: inline-block;
  width: $iconSize;
  height: $iconSize;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;

  &::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/weapons/sniper.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

