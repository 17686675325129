@import "area-theme";

.bg {
  background-color: $backgroundColor;
}

.bg-2 {
  background-color: $backgroundColor2;
}

.bg-3 {
  background-color: $backgroundColor3;
}

.color {
  color: $textColor;
}

.color-highlight {
  color: $headerColor;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-1-1 {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-25 {
  gap: 25px;
}

.gap-50 {
  gap: 50px;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: end !important;
}

.pointer {
  :hover {
    cursor: pointer;
  }
}

.hover-primary {
  :hover {
    color: $textColorHighlight;
  }
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}

.rounded-20 {
  border-radius: 20px;
}

.info {
  color: $infoColor;
}

.warning {
  color: $warningColor;
}

.error {
  color: $errorColor;
}

.info-bg {
  background-color: $infoColor;
}

.warning-bg {
  background-color: $warningColor;
}

.error-bg {
  background-color: $errorColor;
}

.border-bottom-1 {
  border-bottom: 1px solid $accentColor;
}

.border-right-1 {
  border-right: 1px solid $accentColor;
}

.font-important {
  font-size: 20px;
  font-weight: bold;
}

.bg-transparent {
  background-color: transparent;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.table-header-xs {
  @extends: .table-header;
  width: 5%;
}

.table-header-sm {
  @extends: .table-header;
  width: 7.5%;
}

.table-header-md {
  @extends: .table-header;
  width: 10%;
}

.table-header-lg {
  @extends: .table-header;
  width: 15%;
}

.table-header-xl {
  @extends: .table-header;
  width: 25%;
}

.table-header-actions {
  @extends: .table-header;
  width: 10%;
}

.divider {
  width: 1px;
}

.v-divider {
  height: 1px;
  width: 100%;
  background-color: $accentColor;
}

.disabled-row {
  opacity: 0.5;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.game-day-squad-players {
  flex: 1;
  margin: 10px;

  $backgroundOpacity: 0.6;

  &.red {
    background-color: rgb(255, 0, 0, $backgroundOpacity);
  }

  &.blue {
    background-color: rgb(0, 0, 255, $backgroundOpacity);
  }

  &.yellow {
    background-color: rgb(255, 255, 0, $backgroundOpacity);
  }

  &.pink {
    background-color: rgb(255, 0, 255, $backgroundOpacity);
  }
}

.mw-300 {
  max-width: 300px;
}

.w-break {
  word-break: break-all;
}

.bg-image {
  background-image: url('/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.pre-line {
  white-space: pre-line;
}
